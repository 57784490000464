import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Button, Input, InputNumber, Modal, Skeleton, Tooltip, Typography, Divider, DatePicker } from 'antd';
import { icloud } from '../../../redux/api/icloud';
import Swal from 'sweetalert2';

export default function PlaySoundModal(props) {
  const [playAmount, setPlayAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const { Text, Title } = Typography;

  const handleOk = async () => {
    setLoading(true);
    const play = await icloud({
      contract_no: props.contract_no,
      action: 'sound',
      count: playAmount,
    });
    const { success, result } = play.data;
    if (success) {
      Modal.success({
        title: result,
      });
      setLoading(false);
      props.onCancel();
    }
  };

  return (
    <Modal
      open={props.visible}
      onCancel={props.onCancel}
      width={450}
      closable={false}
      okText="ยืนยัน"
      okButtonProps={{ loading: loading }}
      cancelText="ยกเลิก"
      cancelButtonProps={{ disabled: loading }}
      onOk={handleOk}
      className="play-sound-modal">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, margin: '20px 0' }}>
        <ExclamationCircleOutlined style={{ color: '#f29d38', fontSize: 70 }} />
        <Title style={{ textAlign: 'center' }} level={3}>
          ยืนยันการส่งเสียง
          <br />
          ไปยังโทรศัพท์มือถือลูกค้า
        </Title>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
          <Button icon={<MinusOutlined />} onClick={() => setPlayAmount(Math.max(playAmount - 1, 1))} />
          <InputNumber
            className="sound-number"
            size="large"
            type="number"
            placeholder="0"
            onChange={setPlayAmount}
            value={playAmount}
            min={1}
            max={20}
            style={{ margin: '0 5px', borderRadius: '10px' }}
          />
          <Button icon={<PlusOutlined />} onClick={() => setPlayAmount(Math.min(playAmount + 1, 20))} />
        </div>
        <Text type="danger">กดจำนวนที่ต้องการครั้งละไม่เกิน 20</Text>
      </div>
    </Modal>
  );
}
