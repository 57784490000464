// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Table, Typography} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {ReportTrackingFeeSearchForm} from '../../../constants/global/SearchForm';
import {ReportTrackingFeeColumn} from '../../../constants/global/columnTableForm';
import {GET, POST, GET_TRACKING_ACKNOWLEDGMENT} from '../../../services/index';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import {SEARCH_FORM} from './form';
import {TrackingNoReportColumn} from './columns';
import {useHistory} from 'react-router-dom';
import { optionAcknowledgement, optionSendTerminate} from './constant';
import _ from 'lodash';

const {Title} = Typography;

const AcknowledgementReceiptTrackingNoReport = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [hideTable, setHideTable] = useState(true);

  const onGetData = async(params) => {
    try {
      if( _.isEmpty(params.contract_no) &&  _.isEmpty(params.name) &&  _.isEmpty(params.terminate_contract_status) &&  _.isEmpty(params.terminate_export_status)) {
        Modal.error({
          title: 'ไม่สามารถค้นหาข้อมูลได้',
          content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
        });
      } else {
        setLoading(true);
        const payload = {
          contract_no: params?.contract_no || '',
          name: params?.name || '',
          terminate_contract_status: params?.terminate_contract_status || '',
          terminate_export_status: params?.terminate_export_status || '',
        };
        const res = await POST(GET_TRACKING_ACKNOWLEDGMENT, payload);

        const {success, data} = res;
        if (success) {
          setDataSource(
            data.map((el, index) => {
              return {...el, no: index + 1};
            }),
          );
          const dataExport = data.map((el) => {
            return {
              เลขที่สัญญา: el.contract_no,
              'ชื่อ-นามสกุล': el.name,
              TrackingNo: el.tracking_no,
              วันที่จัดส่ง: el.sending_date !== '-' ? moment(el.sending_date).format('DD/MM/YYYY') : el.sending_date,
              สถานะจัดส่ง: el.thaipost_status_thai,
              วันที่รับใบตอบรับ: el.acknowledgment_date !== '-' ? moment(el.acknowledgment_date).format('DD/MM/YYYY') : el.acknowledgment_date,
              "บันทึกเลข Tracking": el.tracking_upload_user || '',
              บันทึกใบตอบรับ: el.acknowledgment_user || '',
            };
          });
          setDataExport(dataExport);
          setHideTable(false);
          setLoading(false);
        }
      }
    }catch (error) {
      Modal.error({title: error?.message});
      setLoading(false);
    } 
  };
  const addTrackingNo = () => {
    history.push({pathname: '/add_AcknowledgementReceipt_tracking_no'});
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงาน Tracking No',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: SEARCH_FORM({options: {export: optionAcknowledgement, send: optionSendTerminate}}),
              onSearch: (data) => onGetData(data),
            }}
            tableLayout={{
              columns: TrackingNoReportColumn(),
              dataSource: dataSource,
              hidden: hideTable,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="CREATE" title={'เพิ่มเลขที่ใบตอบรับ'} onClick={() => addTrackingNo()} />
                  <CSVLink data={dataExport} filename={'รายงาน Tracking No.'}>
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} />
                  </CSVLink>
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default AcknowledgementReceiptTrackingNoReport;
