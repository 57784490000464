import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Modal, Card, Spin, Image} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import Wrapper from '../../views/wrapper/Wrapper';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {GET, POST, GET_REPORT_TRACKING_AGENT, GET_TRACKERS_ID} from '../../services/index';
import {convertStrToFormat} from '../../functions/fn';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ReportTrackingAgentColumn} from '../../constants/global/columnTableForm';
import {ReportTrackingAgentSearchForm} from '../../constants/global/SearchForm';
import {ButtonTheme} from '../../components/buttons';
import {TRACKING_RECORD_STATUS} from '../../constants/status/status';
import EditTrackingTaskModal from '../../components/modal/TrackingTask/TrackingHistoryModal';
import {useGetExportOneTrackingTaskDaily} from './api';
import _ from 'lodash';

const ReportTrackingAgent = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [optionTrackingRecordStatus, setOptionTrackingRecordStatus] = useState([]);
  const [visibleTrackingTaskModal, setVisibleTrackingTaskModal] = useState(false);
  const [dataSearch, setDataSearch] = useState({
    startDate: '',
    endDate: '',
    IDtrackers: '',
    name: '',
    status: '',
    contractNo: '',
    startDateTrackingAgent: '',
    endDateTrackingAgent: '',
  });
  const [record, setRecord] = useState();
  const [visibleImage, setVisibleImage] = useState(false);
  const [sourceImage, setSourceImage] = useState('');
  const [optionTrackers, setOptionTrackers] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [paramsUrl, setParamsUrl] = useState(null);
  const [hideTable, setHideTable] = useState(true);

  const [resultReport, loadingReport, errorReport, setInputReport] = useGetExportOneTrackingTaskDaily();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        closeModal('report');
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      closeModal('report');
    }
  }, [errorReport]);

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('startDate');
    const IDtrackers = params.get('IDtrackers');

    await getIdTrackersOption();
    await getOptionsTrackingRecordStatus();

    setParamsUrl('');

    if (IDtrackers) {
      setDataSearch({
        startDate: startDate,
        endDate: startDate,
        IDtrackers: IDtrackers,
        name: '',
        status: '',
        contractNo: '',
        IDtrackersAgent: IDtrackers,
        startDateTrackingAgent: startDate,
        endDateTrackingAgent: startDate,
      });
      getDataReportTrackingAgent({startDate: startDate, endDate: startDate, IDtrackers: IDtrackers, name: '', status: '', contractNo: ''});
    }
  }, []);

  const getIdTrackersOption = async () => {
    try {
      const res = await GET(GET_TRACKERS_ID);
      const {success, data} = res;
      if (success) {
        const result = data.map((el) => ({label: el.value, value: el._id}));
        setOptionTrackers(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
    }
  };

  const getDataReportTrackingAgent = async (value) => {
    if( _.isEmpty(value?.IDtrackers) && _.isEmpty(value?.name) && _.isEmpty(value?.status) && _.isEmpty(value?.contractNo) && _.isEmpty(value?.startDate) && _.isEmpty(value?.endDate)) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    }else {
      value.startDate = value.startDate ? moment(value.startDate).format('YYYY-MM-DD') : '';
      value.endDate = value.endDate ? moment(value.endDate).format('YYYY-MM-DD') : '';
      value.IDtrackers = value.IDtrackers || '';
      value.name = value.name || '';
      value.status = value.status || '';
      value.contractNo = value.contractNo || '';

      const {startDate, endDate} = value;

      setLoading(true);

      let params = [];

      try {
        Object.keys(value).forEach((key) => {
          if (value[key]) {
            if (key === 'startDate') {
              params.push('startDate=' + convertStrToFormat(startDate, 'dateAD'));
            } else if (key === 'endDate') {
              if (value[key]) {
                params.push('endDate=' + convertStrToFormat(endDate, 'dateAD'));
              } else if (!value[key]) {
                params.push('endDate=' + convertStrToFormat(new Date(), 'dateAD'));
              }
            } else {
              params.push(key + '=' + value[key]);
            }
          }
        });

        let url = GET_REPORT_TRACKING_AGENT;
        if (params.length !== 0) {
          url += '?' + params.join('&');
          setParamsUrl('?' + params.join('&'));
        }

        const res = await POST(url);
        const {success, data} = res;
        if (success) {
          const result = data.map((el) => {
            return {
              trackers: el.TrackersName,
              name: el.customerName,
              distanceKm: el.distance !== '' ? Math.round(parseInt(el.distance) / 1000) || '' : '',
              distanceHomeKm: el?.distanceHome || '',
              job: [el.termination_of_contract.job_type, el.termination_of_contract.job_type_remark],
              tocStatus: el.termination_of_contract.status,
              ...el,
            };
          });

          setDataSource(result);
        }
        setHideTable(false);
      } catch (err) {
        const {message, error} = err;
        Modal.error({title: message, content: error || ''});
      } finally {
        setLoading(false);
      }
    }
  };

  const openModal = (data, ref) => {
    switch (ref) {
      case 'detail':
        setRecord(data);
        setVisibleTrackingTaskModal(true);
        break;
      case 'report':
        setVisibleModal(true);
        break;
      default:
    }
  };

  const closeModal = (ref) => {
    switch (ref) {
      case 'detail':
        setRecord('');
        setVisibleTrackingTaskModal(false);
        break;
      case 'report':
        setVisibleModal(false);
        break;
      default:
    }
  };

  const getOptionsTrackingRecordStatus = async  () => {
    let option = [];
    Object.keys(TRACKING_RECORD_STATUS).forEach((key) => {
      return option.push({
        value: TRACKING_RECORD_STATUS[key].en,
        label: TRACKING_RECORD_STATUS[key].th,
      });
    });
    setOptionTrackingRecordStatus(option);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.map((val) => setValue(val.name, null));

    if (window.location.href.includes('?')) window.history.pushState({}, null, window.location.href.split('?')[0]);

    setDataSearch({
      startDate: '',
      endDate: '',
      IDtrackers: null,
      name: '',
      status: '',
      contractNo: '',
      IDtrackersAgent: null,
      startDateTrackingAgent: '',
    });
  };

  const onCloseImage = (vis) => {
    setVisibleImage(vis);
    setSourceImage('');
  };

  const showImage = (src) => {
    setVisibleImage(true);
    setSourceImage(src);
  };

  const setField = (data, ref) => {
    switch (ref) {
      case 'startDate':
        setDataSearch({...dataSearch, startDate: data.toDate(), startDateTrackingAgent: data});
        break;
      case 'endDate':
        setDataSearch({...dataSearch, endDate: data.toDate(), endDateTrackingAgent: data});
        break;
      case 'IDtrackers':
        setDataSearch({...dataSearch, IDtrackersAgent: data});
        break;
      default:
        break;
    }
  };

  const onExportExcelReport = async () => await setInputReport(paramsUrl);

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานประวัติการติดตามภาคสนามรายบุคคล',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: ReportTrackingAgentSearchForm({option: {optionTrackingRecordStatus, optionTrackers}, dataSearch, setField}),
              onSearch: (data) => getDataReportTrackingAgent(data),
              dataSearchDefault: dataSearch,
              onClearCustom,
            }}
            tableLayout={{
              columns: ReportTrackingAgentColumn({option: {optionTrackingRecordStatus}, openModal, showImage}),
              dataSource: dataSource,
              hidden: hideTable,
              extraContent: (
                <ContainerButton right>
                  {/* <CSVLink data={dataExport} filename={'รายงานประวัติการติดตามภาคสนามรายบุคคล'}>
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} />
                  </CSVLink> */}
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
      <EditTrackingTaskModal visible={visibleTrackingTaskModal} onClose={() => closeModal('detail')} data={record} />
      <Image.PreviewGroup preview={{visible: visibleImage, onVisibleChange: (vis) => onCloseImage(vis)}}>
        <Image style={{display: 'none'}} src={sourceImage} />
      </Image.PreviewGroup>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={() => closeModal('report')}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => closeModal('report')} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default ReportTrackingAgent;
