import _ from 'lodash';

export const isPermission = (menuUser, menuCode, action) => {
  let menuActions;
  for (const menu of menuUser) {
    const findAction = _.find(menu.menu, (item) => {
      return item.menu_code === menuCode;
    });

    if (findAction) {
      menuActions = findAction?.actions;
      break;
    }
  }

  // find roles action
  const resultPermission = _.find(menuActions, (item) => {
    return item === action;
  });

  return resultPermission ? true : false;
};
