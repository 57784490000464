import React, {useState, useEffect} from 'react';
import {Modal} from 'antd';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {REDEEM_PERIOD_FORM} from '../form';
import {GET, POST, PUT, CHANGE_REDEEM_DATE} from '../../../services';
import {Icon} from '../../../resources/icon';
import moment from 'moment';
export const RedeemPeriodModal = ({title, visible, onClose, data, reApi, filterParams}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data?.return_cus_date !== '-') {
      setValue('return_cus_date', moment(data?.return_cus_date, 'YYYY-MM-DD'));
    }
    if (data?.remark) {
      setValue('remark', data?.remark);
    }
  }, [data]);

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการไถ่ถอนเครื่อง?',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload = {
        contract_no: data?.contract_no,
        date: moment(params?.return_cus_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        remark: params?.remark,
      };
      let res = await POST(CHANGE_REDEEM_DATE, payload);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onClose();
            reApi(filterParams);
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal title={title || 'modal'} open={visible} width={480} onCancel={onClose} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(openConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={REDEEM_PERIOD_FORM()}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
                <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
