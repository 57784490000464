import { useEffect, useState, useReducer } from 'react';
import { axiosInstance } from '../../../oauth';
import { API_REPORT_SERVICE } from '../../../env';

export const useGetReportSecondHandExcel = () => {
  const [input, setInput] = useState(null);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [needRefresh, refresh] = useReducer((s) => !s, false);

  useEffect(() => {
    if (!input) return;
    let abort = false;
    fetchReport(input);
    return () => {
      abort = true;
    };

    async function fetchReport(input) {
      setLoading(true);

      try {
        const response = await axiosInstance.get(`${API_REPORT_SERVICE}/second_hand/export_second_hand_aph`, {params: input});

        if (response?.data) setResult(response?.data);
      } catch (err) {
        if (!abort) setError(err);
      } finally {
        if (!abort) {
          setLoading(false);
          setInput(null);
        }
      }
    }
  }, [input, needRefresh]);

  return [result, loading, error, setInput, refresh];
};