// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin, Typography } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { GET, POST, GET_REPORT_TRACKING_DAILY, GET_TRACKERS_ID } from '../../services/index';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import { ReportTrackingDailyColumn } from '../../constants/global/columnTableForm';
import { ReportTrackingDailySearchForm } from '../../constants/global/SearchForm';
import { ButtonTheme } from '../../components/buttons';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { convertStrToFormat, generatedOptionWithData } from '../../functions/fn';
import _ from 'lodash';

const { Title } = Typography

const ReportTrackingDaily = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const [optionTrackers, setOptionTrackers] = useState([]);
  const { user } = useCurrentState(auth);
  const [hideTable, setHideTable] = useState(true);

  useEffect(async () => {
    getIdTrackersOption()
    // await getDataReportTrackingDaily({});
  }, []);

  const getIdTrackersOption = async () => {
    try {
      const res = await GET(GET_TRACKERS_ID);
      const { success, data } = res;
      if (success) {
        const result = data.map((el) => {
          return {
            label: el.value,
            value: el._id
          }
        })
        setOptionTrackers(result)
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  }

  const getDataReportTrackingDaily = async (value) => {
    if( _.isEmpty(value?.IDtrackers) && _.isEmpty(value?.startDate) && _.isEmpty(value?.endDate)) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    }else {
      // if (value?.date) {
      //   value.date[0] = value?.date[0] ? moment(value.date[0]).format('YYYY-MM-DD') : '';
      //   value.date[1] = value?.date[1] ? moment(value.date[1]).format('YYYY-MM-DD') : '';
      // } else {
      //   value.date = ['', '']
      // }

      value.startDate = value?.startDate ? moment(value.startDate).format('YYYY-MM-DD') : '';
      value.endDate = value?.endDate ? moment(value.endDate).format('YYYY-MM-DD') : '';
      value.IDtrackers = value.IDtrackers || '';

      // const { date, firstname, surname } = value
      const { startDate, endDate, IDtrackers } = value

      setLoading(true);

      try {

        const obj = {
          startDate: startDate,
          endDate: endDate,
          IDtrackers: IDtrackers,
        }

        const res = await POST(GET_REPORT_TRACKING_DAILY, obj);
        const { success, data } = res;
        if (success) {

          const dataEx = data.map((el) => {
            return {
              'วันที่ติดตาม': el.trackingDate,
              'ชื่อผู้ติดตาม': el.trackers,
              'Check-In': el.checkin?.location?.name,
              'Time-In': el.checkin?.time,
              'ปิดสัญญา': el.close,
              'คืนเครือง': el.return,
              'นัดหมาย': el.appointment,
              'ไม่สามารถติดตามได้': el.not_tracked,
              'ไม่พบลูกค้า': el?.not_found ? el?.not_found: '0',
              'ปิดเคส': el?.finished ? el?.finished: '0',
              'อื่นๆ': el.other,
              'รวมจำนวนเคสที่ติดตามแล้ว': el.total,
              'Check-Out': el.checkout?.location?.name,
              'Time-Out': el.checkout?.time,
              'ระยะทางรวม': el?.totalDistance ? el?.totalDistance: '0',
              'ระยะทางกลับบ้าน': el?.distanceHome ? el?.distanceHome: '0'
            }
          })

          setDataExport(dataEx)
          setDataSource(data.map((el)=>{return{...el,trackingDate:el.trackingDate?moment(el.trackingDate,'YYYY/MM/DD').format('DD/MM/YYYY'):''}}))
        }
        setHideTable(false);
      } catch (err) {
        const { message, error } = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'รายงานการติดตามภาคสนามรายวัน',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: ReportTrackingDailySearchForm({ option: { optionTrackers } }),
              onSearch: (data) => getDataReportTrackingDaily(data),
            }}
            tableLayout={{
              columns: ReportTrackingDailyColumn({ data: dataSource }),
              dataSource: dataSource,
              hidden: hideTable,
              extraContent: (
                <ContainerButton right>
                  <CSVLink data={dataExport} filename={"รายงานการติดตามภาคสนามรายวัน"}><ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} /></CSVLink>
                </ContainerButton>),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default ReportTrackingDaily;