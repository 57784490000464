import moment from 'moment';
import {createContext, useState, useEffect, useCallback} from 'react';
import {Modal} from 'antd';
import {GET, GET_SENDIND_STATUS, GET_CONTRACT_STATUS, GET_TRACKING_STATUS, GET_TRACKING_TASK_SUMMARY} from '../../services';
import {convertStatusENtoTH} from '../../constants/status/status';
import {buildArrayOfObjectWithNoDulplicateKey, convertStrToFormat} from '../../functions/fn';
import {getTrackingNo} from '../../utils/api_other_domain';
import _ from 'lodash';

const TrackingNoContext = createContext({
  isLoading: false,
  data: [],
  dataSource: [],
  dataExport: [],
  optionContractNo: [],
  optionCustomerName: [],
  paramsUrl: null,
  onSearch: (filter) => {},
});

export const TrackingNoContextProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [sendingOptions, setSendingOptions] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [trackingOptions, setTrackingOptions] = useState([]);
  const [optionContractNo, setOptionContractNo] = useState([]);
  const [optionCustomerName, setOptionCustomerName] = useState([]);
  const [summary, setSummary] = useState(null);
  const [paramsUrl, setParamsUrl] = useState(null);
  const [summaryList, setSummaryList] = useState([]);
  const [hideTable, setHideTable] = useState(true);

  const dataExportResult = (data) => {
    const result = data.map((item) => {
      return {
        ลำดับที่: item.key,
        เลขที่สัญญา: item.contractNO,
        ชื่อ: item.name,
        ประเภทงาน: item.jobType,
        สถานะสัญญา: convertStatusENtoTH(item.contractStatus, 'contract'),
        สถานะการจัดส่ง: convertStatusENtoTH(item.status, 'sending'),
        วันที่จัดส่ง: convertStrToFormat(item.sentDate, 'datetime'),
        วันที่จัดส่งสำเร็จ: convertStrToFormat(item.arrivedDate, 'datetime'),
        จัดส่งสำเร็จ: item.durationAfterArrived,
        สถานะการติดตาม: convertStatusENtoTH(item.trackingTaskStatus, 'tracking'),
        จำนวนที่จัดส่ง: item.parcelCount,
      };
    });
    return result;
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [trackingno, sending, contract, tracking, summary] = await Promise.all([
        await getTrackingNo(''),
        GET(GET_SENDIND_STATUS),
        GET(GET_CONTRACT_STATUS),
        GET(GET_TRACKING_STATUS),
        GET(GET_TRACKING_TASK_SUMMARY),
      ]);

      setParamsUrl('');

      const trackingNoData = trackingno.data;

      if (trackingNoData.success) {
        trackingNoData.result.map((item, index) => {
          item.key = index + 1;
          item.job = [item.jobType, item.jobTypeRemark];
        });

        let opContractArr = [];
        let opCustomerArr = [];

        const buildContractNo = buildArrayOfObjectWithNoDulplicateKey('contractNO', trackingNoData.result);
        buildContractNo.forEach((val) => opContractArr.push({value: val.contractNO, label: val.contractNO}));

        const buildName = buildArrayOfObjectWithNoDulplicateKey('name', trackingNoData.result);
        buildName.forEach((val) => opCustomerArr.push({value: val.name, label: val.name}));

        setOptionContractNo(opContractArr);
        setOptionCustomerName(opCustomerArr);
        setData(trackingNoData.result);
        setDataSource(trackingNoData.result);
        setDataExport(dataExportResult(trackingNoData.result));
      }

      if (sending.success) setSendingOptions(sending.result);
      if (contract.success) setContractOptions(contract.result);
      if (tracking.success) setTrackingOptions(tracking.result);
      if (summary.success) setSummary(summary.result);
    } catch (err) {
      Modal.error({title: err?.message || err?.data?.message || 'error'});
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getOption = async() => {
    const [sending, contract, tracking, summary] = await Promise.all([
      GET(GET_SENDIND_STATUS),
      GET(GET_CONTRACT_STATUS),
      GET(GET_TRACKING_STATUS),
      GET(GET_TRACKING_TASK_SUMMARY),
    ]);

    if (sending.success) setSendingOptions(sending.result);
    if (contract.success) setContractOptions(contract.result);
    if (tracking.success) setTrackingOptions(tracking.result);
    if (summary.success) setSummary(summary.result);
  }

  useEffect(() => {
    getOption();
  }, []);

 
  useEffect(() => {
    if (!isLoading && summary) {
      setSummaryList([
        {label: 'สามารถติดตามได้ : ', value: summary.canTrack, color: 'geekblue'},
        {label: 'กำลังติดตาม : ', value: summary.tracking, color: 'gold'},
        {label: 'ติดตามเสร็จสิ้น : ', value: summary.done, color: 'green'},
      ]);
    }
  }, [isLoading, summary]);

  const getFilteredData = async (data) => {
    if( _.isEmpty(data?.contractNO) && _.isEmpty(data?.contractStatus) && _.isEmpty(data?.date) && _.isEmpty(data?.name) && _.isEmpty(data?.status) && _.isEmpty(data?.trackingTaskStatus)) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    }else {
      setIsLoading(true);
      let params = [];
      try {
        Object.keys(data).forEach((key) => {
          if (data[key]) {
            if (key === 'date') {
              if (data[key][0]) params.push('startSentDate=' + moment(convertStrToFormat(data[key][0], 'timetozero-utc')).format('YYYY-MM-DD'));
              if (data[key][1]) params.push('endSentDate=' + moment(convertStrToFormat(data[key][1], 'timetozero-utc')).format('YYYY-MM-DD'));
            } else {
              params.push(key + '=' + data[key]);
            }
          }
        });

        let paramsWithNoUrl = '';
        if (params.length !== 0) paramsWithNoUrl += '?' + params.join('&');

        setParamsUrl(paramsWithNoUrl);

        let response = await getTrackingNo(paramsWithNoUrl);

        const {success, result, message} = response.data;

        if (success) {
          const resultData = result.map((item, index) => ({
            key: index + 1,
            job: [item.jobType, item.jobTypeRemark],
            ...item,
          }));
          setDataSource(resultData);
          setDataExport(dataExportResult(resultData));
        } else {
          Modal.error({title: message});
        }
        setHideTable(false);
      } catch (err) {
        Modal.error({title: err?.data?.message});
      } finally {
        setIsLoading(false);
      }
    }
  };

  const context = {
    isLoading: isLoading,
    data: data,
    dataSource: dataSource,
    dataExport: dataExport,
    contractOptions: contractOptions,
    sendingOptions: sendingOptions,
    trackingOptions: trackingOptions,
    summary: summaryList,
    optionContractNo: optionContractNo,
    optionCustomerName: optionCustomerName,
    paramsUrl: paramsUrl,
    getFilteredData: getFilteredData,
    hideTable: hideTable
  };

  return <TrackingNoContext.Provider value={context}>{props.children}</TrackingNoContext.Provider>;
};

export default TrackingNoContext;
